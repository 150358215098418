.panipurikrishnagar{
    background-color: #FFC900;
}

  .conatcttextcolor h1{
    color: #EC1D25
  }

  .colortext{
    color: #690000;
  }

  .maountainernaida{
    background-color: #fbf9dc;
  }

  .krishnagrofficemaountainer{
    background-color: #172c64;
    color: white;
  }