.Mountaineersindia{
background-color: rgb(13, 78, 135);
}


.application{
    font-family: "Playwrite IN", serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

.justify{
    text-align: justify;
    text-justify: inter-word;
}

.mountainerindian{
    background-color: rgb(229, 243, 251);
}

.gallerycolor{
    color: rgb(14, 57, 109);
}

.rccfrom{
    background-color: #f7faff;
    border: none;
    color: rgb(14, 39, 106);
    border-radius: 5px;
    padding: 5px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
}


.blink { 
    animation: blink-animation 1s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }
  

  .coloractivity h1{
    color: #093f9b;
  }

  .borderradius img{
    border-radius: 9px;
  }

  .justifynewtext{
    text-align: justify;
    text-justify: inter-word;
  }

  .backcolor{
    background-color: #076b7c;
  }