.navcolor a{
    color: #1F78AB; 
}
.navcolor h5{
    color: #1F78AB; 
}

.MountaineersAssociationofKrishnanaga{
    background-color: #1F78AB;
}

.navbutton{
    background-color: #14305F; /* Green */
    border: none;
    color: white;
    border-radius: 5px;
    padding: 5px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px; 
}